.main-header {
  position: absolute;
  z-index: 2;
  top: 0;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-end;
  padding: 20px;

  .contact-nav {
    ul {
      display: flex;
      column-gap: 20px;
      list-style: none;
    }

    &__icon {
      position: relative;
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      transition: transform 0.2s;
  
      &::before {
        position: absolute;
        display: block;
        width: 70%;
        height: 70%;
        border-radius: 50%;
        background-color: transparent;
        content: "";
        transition: background 0.2s ease-in;
      }
  
      &:hover {
        transform: scale(1.1);

        &.mail::before {
          background: #7d0808;
        }
  
        &.linkedin::before {
          background: #0a66c2;
        }
  
        &.instagram::before {
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
        }
  
      }
    }

    a {
      display: flex;
      align-items: center;
      color: #fff;
      column-gap: 10px;
      font-family: "founders_grotesk_x-condenseBd", sans-serif;
      font-size: 0.5rem;
      text-decoration: none;
      text-transform: uppercase;
    }

    img {
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}