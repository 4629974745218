.hero-section {
  position: relative;
  height: 100vh;

  // height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  text-transform: uppercase;
  transition: opacity 0.3s 0.3s;

  .load-complete & {
    opacity: 1;
  }

  &__sub-heading {
    position: absolute;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    padding-left: 5vw;
    color: #fff;
    opacity: 0;
    row-gap: 6vmin;
    transition: opacity 0.4s 0.2s;
      
    .transition-complete & {
      opacity: 1;
    }
  
    h2 {
      font-family: "founders_grotesk_x-condenseBd", sans-serif;
      font-size: 25vmin;
      line-height: 0.7;
      perspective: 400px;
    }

    p {
      font-family: 'Nanum Myeongjo',
        serif;
      font-size: 3vmin;
      text-transform: none;
    }
  }
}

.webgl {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1.2s 0.6s;
  
  .transition-half-complete & {
    opacity: 1;
  }
}