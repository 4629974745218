* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  min-height: 100vh;
  font-size: calc(1.5rem + 1.2vw);
  overflow-x: hidden;
}

body {
  background-color: #000;

  // font-family: 'Bebas Neue', cursive;
  font-family: 'Playfair Display', serif;
}

@font-face {
  font-family: 'founders_grotesk_x-condenseBd';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/foundersgroteskxcond-bold-webfont.woff2') format('woff2'),
    url('/static/fonts/foundersgroteskxcond-bold-webfont.woff') format('woff');

}

.dg.ac {
  z-index: 99999!important;
}

.logo {
  position: fixed;
  z-index: 10;
  top: 20px;
  left: 20px;
  color: #fff;
  font-family: "founders_grotesk_x-condenseBd", sans-serif;
  font-size: 2vmin;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
}