$bar-color: #fff;
$ball-color: #fff;
$bg-color: #EA4961;

.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;

  .transition-half-complete & {
    display: none;
  }

  &__animation {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    width: 75px;
    height: 100px;
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s;
    
    body.load-complete & {
      opacity: 0
    }
    
    &-bar {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 50%;
      background: $bar-color;
      box-shadow: 1px 1px 0 rgb(0 0 0 / 20%);
      transform-origin: center bottom;
    
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          left: ($i - 1) * 15px;
          animation: barUp#{$i} 4s infinite;
          transform: scale(1, $i * .2);
        }
      }
    
    }
    
    &-ball {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      animation: ball 4s infinite;
      background: $ball-color;
    }
  }

}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }

  5% {
    transform: translate(8px, -14px);
  }

  10% {
    transform: translate(15px, -10px)
  }

  17% {
    transform: translate(23px, -24px)
  }

  20% {
    transform: translate(30px, -20px)
  }

  27% {
    transform: translate(38px, -34px)
  }

  30% {
    transform: translate(45px, -30px)
  }

  37% {
    transform: translate(53px, -44px)
  }

  40% {
    transform: translate(60px, -40px)
  }

  50% {
    transform: translate(60px, 0)
  }

  57% {
    transform: translate(53px, -14px)
  }

  60% {
    transform: translate(45px, -10px)
  }

  67% {
    transform: translate(37px, -24px)
  }

  70% {
    transform: translate(30px, -20px)
  }

  77% {
    transform: translate(22px, -34px)
  }

  80% {
    transform: translate(15px, -30px)
  }

  87% {
    transform: translate(7px, -44px)
  }

  90% {
    transform: translate(0, -40px)
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 {
  0% {
    transform: scale(1, .2);
  }

  40% {
    transform: scale(1, .2);
  }

  50% {
    transform: scale(1, 1);
  }

  90% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, .2);
  }
}

@keyframes barUp2 {
  0% {
    transform: scale(1, .4);
  }

  40% {
    transform: scale(1, .4);
  }

  50% {
    transform: scale(1, .8);
  }

  90% {
    transform: scale(1, .8);
  }

  100% {
    transform: scale(1, .4);
  }
}

@keyframes barUp3 {
  0% {
    transform: scale(1, .6);
  }

  100% {
    transform: scale(1, .6);
  }
}

@keyframes barUp4 {
  0% {
    transform: scale(1, .8);
  }

  40% {
    transform: scale(1, .8);
  }

  50% {
    transform: scale(1, .4);
  }

  90% {
    transform: scale(1, .4);
  }

  100% {
    transform: scale(1, .8);
  }
}

@keyframes barUp5 {
  0% {
    transform: scale(1, 1);
  }

  40% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, .2);
  }

  90% {
    transform: scale(1, .2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.transition {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .transition-complete & {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      position: relative;
      display: block;
      overflow: visible;
      fill: none;
      fill-rule: nonzero;
      mix-blend-mode: exclusion;
      stroke: #fff;
      stroke-dasharray: 137rem;
      stroke-dashoffset: 137rem;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 4;
      stroke-width: 7rem;
    }
    
  }

  &__heading {
    position: absolute;
    z-index: 1;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #000;
    opacity: 0;
    text-align: center;
    transition: opacity 0.2s 0.3s;

    h1 {
      font-family: "founders_grotesk_x-condenseBd", sans-serif;
      font-size: 25vmin;
      line-height: 0.7;
    }

    .load-complete & {
      opacity: 1;
    }

    .transition-half-complete & {
      opacity: 0;
    }
  }
}